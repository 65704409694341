import React,{useState} from "react";
import style from "../../../styles/mystyle.module.css";
import { Navbar } from "../Layout/Navbar";
import dynamic from "next/dynamic";
const Sponsor = dynamic(() => import('./Sponsor'),{ssr:false})
const SquareBackground = dynamic(() => import('./SquareBackground'),{ssr:false})
const LeftSquare = dynamic(() => import('./LeftSquare'),{ssr:false})
function Indexsectionwhite() {
  const [exchangeCurrenciesRes,setExchangeCurrenciesRes]=useState([])
  const [currencies,setCurrencies]=useState([])
  return (
    <div className={style.ContainerMargin + " " + style.BgWeb} >
      <Navbar showDownloadAppSection={true} exchangeAllCurrenciesCallBack={(res)=>setExchangeCurrenciesRes(res)} currenciesCallBack={(res)=>setCurrencies(res)}>
       
        <Sponsor /> 
        <SquareBackground /> 
        <LeftSquare exchangeCurrenciesRes={exchangeCurrenciesRes} currencies={currencies}/>
      </Navbar>
    </div>
  );
}

export default Indexsectionwhite;
