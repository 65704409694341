import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'next-i18next'
import * as config from '../../../next.config'
export default function SiteSeo(props) {
   const {t}=useTranslation("common") 
    return (
   
        <Head>
            <title>{`${t("Hakeem")} | ${props.title}`}</title>
            {props.meta && props.meta.map(function (item, index) {
                return (
                    <React.Fragment key={index}>
                    <meta name={item.name} content={item.content} key={index}></meta>
                    <meta property="og:description" content={item.content} ></meta>
                    <meta property="og:title" content={`${t("Hakeem")} | ${props.title}`} ></meta>
                   {item.image?<meta property="og:image" content={item.image} ></meta>:<meta property="og:image" content={config.basePath+"/assest/logo.svg"} ></meta>} 
                    {item.url?<meta property="og:url" content={item.url} ></meta>:""}
                </React.Fragment>
                );
            })}
        </Head>
  
    )
}
