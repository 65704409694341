import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Indexsectionwhite from "../components/components/Home/indexsectionwhite";
import SiteSeo from "../components/components/seo/SiteSeo";
import { useTranslation } from "next-i18next";
import KeyWordsData from '../components/components/KeyWords/KeyWords'
import buildQueryForKeywordsFunction from './api/cms/keywords/buildQueryForKeywordsFunction'
import {getCookieCountry} from '../pages/api/helpers/HeaderRequest'
export default function index({keyWords,countryOfUse}) {
  const { t } = useTranslation("common");
  let meta = [
    {
      name: "description",
      content:t("searchPageMetaDescription")
    }
  ];

  return (
    <div>
  
      
      <>
      <div style={{display:"none"}}>
        <h1>{t("header1")}</h1>
        <h2>{t("header2")}</h2>
        <p>{t("paragraph1")}</p>
        <p>{t("paragraph2")}</p>
        <p>{t("paragraph3")}</p>
      </div>
      <KeyWordsData keyWords={JSON.parse(keyWords)}/>
      <SiteSeo title={t("homeHeadTitle")} meta={meta}/>
      <Indexsectionwhite
       countryOfUse={countryOfUse} 
       />
      </>
    </div>
  );
}
export async function getServerSideProps({ locale,req,res}) {
  let dataOfQuery={}
  
  dataOfQuery.langCode=locale
  let getKeywords=await buildQueryForKeywordsFunction(dataOfQuery,req)
  let country = getCookieCountry(req) != null ? getCookieCountry(req).userCountry:null

  return {
    props: {
      countryOfUse:country !=undefined ?country:null,
      keyWords:JSON.stringify(getKeywords.content),
      revalidate:10,
      ...(await serverSideTranslations(locale, ["common"]))
    }
  };
}
