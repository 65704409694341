
import {stripeLnLrWithBr } from "../../helpers/helpers";
function KeyWords(props) {
 

  return (
    <div>
 
      {props.keyWords && props.keyWords.length > 0
        ? props.keyWords.map((i,index) => {
            return <div
            key={index}
            dangerouslySetInnerHTML={{
              __html:i.keywordText? stripeLnLrWithBr(i.keywordText):""
            }}
              style={{ display:"none"  }}
            ></div>;
          })
        : ""}
    </div>
  );
}
export default KeyWords;
